.App {
  text-align: center;
}

.main-container {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  position: relative;
}

.bg-green1 {
  background-color: #1A4C39!important;
  &:hover {
    background-color: #06964c!important;
  }
}

button {
  &.color-green {
    color: #1A4C39;
    &:hover {
      color: #06964c;
      background: none;
    }
  }
}

.container {
  padding: 50px 50px;
}

.body-container {
  padding: 25px 50px;
  margin-bottom: 25px;
}

h1 {
  font-size: 40px;
}

h1, h2, h3 {
  margin:20px 0px
}

.m-0 {
  margin:0
}

img {
  display: flex;
  height: 85px;
  /* margin-left: 40px; */
  padding-bottom: 5px;
  padding-top: 5px;
  width: 250px;
}

ul {
  margin: 15px 0px;
  padding: 0px 30px;
}

ul li {
    line-height: 24px;
    margin-bottom: 10px;
    color: grey;
    font-size: 18px;
    line-height: 24px;
}

.navbar {
  background-color: #333; /* #211d1d; */
  margin-top: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  /* for fixing the navmenu */
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.8s ease;
  z-index: 999999999;
}

.navbar a img {
  width: 200px;
  padding: 0;
  height: 70px;
}

.navbar.scrolled {
  height: 80px;
}
.navdiv {
  padding-top: 10px;
  flex-basis: 43%;
}

.navdiv ul {
  display: inline-flex;
  margin: 0;
  padding: 0;
}

nav ul {
  margin-top: 0;
  padding-top: 0;
}

nav ul li {
  list-style: none;
  padding: 0px 16px!important;
  width: fit-content!important;
  padding-right: 0px!important;
}

li a {
  text-align: center;
  color: whitesmoke;
  font-size: 18px;
  /* font-weight: bold; */
  margin-right: 20px;
  text-decoration: none;
  margin-top: 0;

}

.navdiv .menu-icon {
  display: none;
}

.navdiv .sx-profile {
  display: none;
}

.profile-img {
  width: 52px;
  height: 60px;
  border-radius: 50%;
}

.icon-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: pink;
  text-transform: capitalize;
  font-size: 25px;
  font-weight: 600;
  align-items: center;
  display: inline-flex;
  justify-content: center;
}

.sx-profile {
  display: none;
}

.drawer-list .xs-list-item {
  display: none;
}

.downbox ul li a {
  color: #333;
}

.downbox.profile-dropdown {
  position: absolute;
  z-index: 999999999;
}

.btn-group {
  flex-basis: 25%;
  display: flex;
  justify-content: space-evenly;
}

/* login page desktop css */
.login-container .nav-logo {
  position: absolute;
  top: 0;
  left: 0;
}

.login {
  display: flex;
  height: 100vh;
  align-items: center;
}

.otp_login {
  /* background: #d8a8a8;  */
}

.sso_login {
  background-image: linear-gradient(to bottom, #ed9121, #c9740c);
}

.otp_login, .sso_login {
  flex-basis: 50%;
  height: inherit;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.otp_resend_box {
  box-shadow: 0px 0px 5px 3px #24242470;
  padding: 20px 10px;
  width: 350px;
}

.resend_box .btn.login_btn {
  padding: 1px 5px;
  text-transform: capitalize;
  margin-top: 0px;
}

.resend_box .resend-otp {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
}

.resend-otp .resend_btn {
  background-color: transparent!important;
  color: #333!important;
  text-transform: capitalize;
  box-shadow: none;
  padding: 0;
}

.resend-otp .resend_btn:hover {
  background-color: transparent!important;
  box-shadow: none;
  color: #ed9121 !important;;
}

.otp_login .otp_box{
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp_box input {
  height: 30px;
  border-radius: 6px;
  border: 1px solid #333;
}

.email_phone_box {
  box-shadow: 0px 1px 7px 1px #1A4C39;
  width: 60%;
  padding: 30px;
  background-color: #ebebeb1a;
}

.email_phone_box h4 {
  margin-bottom: 30px;
  color: #1A4C39;
}

.login_form {
  margin: 0px 0px 25px 0px;
}

.login_form input {
  border: 1px solid black;
  border-radius: 8px;
  height: 30px;
  width: 60%;
}

.phone_login {
  margin-top: 25px;
}

.btn.login_btn {
  margin-top: 20px;
}

.navlinkbtn {
  text-decoration: none;
  color: #1A4C39;
  font-weight: 600;
}

.navlinkbtn:hover {
  color: #06964c;
}

.driving-sec {
  position: relative;
}

.driving-sec .MuiGrid-container {
  align-items: center;
}

/* .drive-txt{
  margin-left: 10px;
} */
.drive-txt h1 {
  font-size: 40px;
  color:  #F26F21;
}

.drive-txt h3 {
  font-size: 35px;
}

.drive-txt p {
  font-size: 16px;
  color: #1e1d1d;
  line-height: 24px;
  text-align: left;
}

.divBtn {
  display: flex;
}

.divBtn-grps {
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0px;
}

.globe-wall{
  background-repeat: repeat;
  background-position: center;
  background-attachment: local;
  background-size: contain;
}

.globe-wall p {
  font-size: 30px;
}

.Redefining_sec {
  position: relative;

}

.Redefining_sec h3 {
  color: #F26F21;
  font-size: 40px;

}

.Redefining_sec h2 {
  font-size: 38px;
}

.Redefi {
  width: 80%;
  text-align: center;
  display: inline-flex;
  font-size: 16px;
  line-height: 24px;
  color: #1e1d1d;
}

.footer {
  background-color: rgb(250, 243, 243);
}

.g-play {
  margin-left: 40%;

}

.stor {
  margin-left: 40%;

}

.demo-txt {
  color: #fff;
}

.wallpaper {
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 100%;
  height: 600px;
  /* filter:url(#darken900); */
  filter: brightness(80%);
}

.wallpaper button {
  display: none;
}

.wall-txt {
  margin-top:0px;
  padding: 100px 0px 0px 0px;
  font-size: 70px;
  color: #F26F21;
  text-align: center;
  /*filter: brightness(2000%);
  /* text-shadow: 2px 2px black; */
}

.gif-img {
  margin-left: 50px;
  width: 530px;
  height: 480px;
}

/* css added by divyansh */

.demo_btn {
  border-radius: 20px;
  background-color: #1A4C39!important;
  color: #fff!important;
}

.demo_btn:hover {
  background-color: #06964c!important;
}
.MuiButtonBase-root.demo_btn:hover {
  color: #fff
}

.ex-demo {
  background-repeat:no-repeat; 
  background-size:cover;
  height: 200px
}

.demo_btn.ex-demo-btn {
  margin-left: 0px;
  margin-bottom: 10px;
}

.footer_privacy {
  display: flex;
justify-content: center;
align-items: center;
}

/* contact */

.contact-container {
  display: flex;
  margin-top: 50px;
}

.contact-container h3 {
  color:#F26F21;
  font-size: 25px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  flex-basis: 30%;
  padding: 0px 30px;
  background: #F9F9FB;
  height: fit-content;
}

.contact-form .MuiInputLabel-root, .contact-form .Mui-focused{
  color: #1e1d1d!important;
}

.contact-form .submit-btn {
  margin:15px 0px;
  background-color:#F26F21;
  border-radius: 20px;
}

.address-price-container {
  display: flex;
    flex-direction: column;
    flex-basis: 70%;
}

.address-container {
  display: flex;
    flex-direction: row;
}
.contact-details {
  margin: 0px 20px;
  flex-basis: 50%;
}

.contact-address {
  text-align: left;
  flex-basis: 50%;
}

img.pricing {
  width: 750px;
  height: 500px;
}

.payment {
  text-align: center;
}

.car-image{
  display: flex;
  flex-direction: row;
}

.car-image img {
  flex-basis: 33.33%;
  height: 250px;
}

.car-image img:nth-child(1){
  margin-left: 0px;
}
/* services */

.service-container {
  margin-top: 50px;
}

.service-container h1, .terms-container h1, .cancel-container h1 {
  color: #F26F21;
}

.service-para {
  font-size: 16px;
  line-height: 24px;
  color: #1e1d1d;
  text-align: left;
}

/* privacy */

/* common */
.privacy-container, .terms-container, .refund-container {
  text-align: left;
}

.privacy-container h3, .terms-container h3, .refund-container h3{
  font-size: 25px;
  color: #F26F21
}

.privacy-container p, .refund-container p, .contact-container p {
  color: #1e1d1d;
  font-size: 16px;
  line-height: 24px;
}

/* terms */

/* paymet form */

.payment-form {
  box-shadow: 0px 1px 10px 2px grey;
  width: 60%;
  display: inline-flex;
  flex-direction: column;
  padding: 15px 0px;
  margin-top: 35px;
}

.payment-row {
  display: flex;
  margin: 20px;
  align-items: center;
}

.payment-row:nth-child(2) {
  margin-top:0px;
}

.input-label {
  font-size: 16px;
    padding: 10px;
    margin-right: 4px;
    text-align: right;
    width: 120px;
}

.col-input {
  display:flex;
  justify-content: center;
}

.col-input input {
  border: 1px solid grey;
  /* width: 60%; */
  width: auto;
}

.col-span {
  position: relative;
}

.col-span .amount-type {
  position: absolute;
  top: 35%;
  right: 10px;
  font-size: 12px;
  color: grey;
}

/* traxn css */

.successTraxn {
  color: green;
}

.failTraxn {
  color: rgb(133, 23, 23);
}

.traxn-detail {
  color: #333;
  font-weight: 600;
}

.table {
  border: 2px solid rgb(230, 103, 56)
}

.table tbody tr td {
  border: none;
  position: relative;
}

.table tbody tr td:nth-child(1){
  font-weight: 600;
}

.btn {
  margin-top:30px
}

.link_btn {
  text-decoration: none;
  color: white;
  background: #F26F21;
  border-radius: 20px;
  padding: 9px;
}

/* tooltip css */


 #copied {
  visibility: hidden;
  width: 50px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 6px;
  position: absolute;
  right: 32%;
  bottom: 80%;
  transition: opacity 0.3s;
}

#copied::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.copyicon {
  cursor: pointer;
}

.error {
  color: red;
  display: flex;
  width: 100%;
  justify-content: center;
}

.alert-box{
  position: fixed;
  top: 105px;
  right:0;
  animation-name: alertBox;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  overflow:visible;
}

.alert-box .MuiAlert-message {
  font-size: 14px;
  color: #fff;
  overflow:visible;
}

.chef-list-container {
  position: relative;
}

.chef-row {
  display: flex;
  align-items: center;
}

.chef-row .chef-image {
    width: 40%;
    margin: 0 auto;
    height: 120px;
}

.flip-card {
  display: flex;
  flex-direction: column;
  width: 400px;
  height: 300px;
  margin: 0px 20px 20px 0px;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.2)!important;
}

.flip-inner {
  width: 100%!important;
  height: 100%;
  position: relative!important;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-inner {
  transform: rotateY(180deg);
}

.flip-front, .flip-back {
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  top: 0;
}

.body-details {
  display: flex;
  justify-content: space-around;
}

.body-details div {
  display: flex;
  align-items: center;
}

.body-details h3 {
  margin: 0;
}

.flip-back{
  transform: rotateY(180deg);
}

.flip-back ul li {
  margin: 0;
}

.flip-back .list-detail{
  padding: 0;
  justify-content: space-between;
  gap: 10px;
  color: #333;
  display: flex;
  align-items: center;
}

.list-detail h5{
  margin: 0;
}

.book-now {
  width: 100%;
}

.pagination {
  margin: 50px 0px;
}

.pagination ul {
  justify-content: center;
}

/* filter section style */

.filters-section {
  display: flex;
  justify-content: space-evenly;
  margin: 30px 0px;
}

.filter-appbar {
    position: absolute;
    width: 20%;
    top: 10px;
    right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
}

.filters-section .filter-bar {
    position: absolute;
    width: 40px;
    background-color: #1A4C39;
}

.filters-section .filter-bar:hover{
  background-color: #06964c;
}

.filter-bar button {
  justify-content: flex-start;
}

.filters-section .filter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.filter p {
  margin: 10px;
}

.filter .selectbox {
  width: 80%;
}
.exp-filter .exp-slider {
  width: 80%;
}

.button-grps {
  display: flex;
  justify-content: space-between;
}

.filter-body .filter-btn {
  margin: 30px 0px;
  padding: 5px 10px;
  text-transform: capitalize;
  border-radius: 10px;
}

/* wishlist */
.flip-card .added {
  color: #e62727
}

/* cart icon */
.cart-icon {
  color: #fff;
}

.cart-icon .MuiSvgIcon-root {
  width: 35px;
  height: 35px;
}

.booked {
  background-color: #7a7e7c!important;
  pointer-events: none!important;
  width: 100%;
}
@keyframes alertBox {
  from {width: 0%;}
  to {width: 30%;}
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Banner section styling */
.banner-section {
  background-image: linear-gradient(to bottom right, #c9740c, #ed9121);
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: 50px;

  .overtext {
    position: absolute;
    top: 20%;
    left: 10%;
    text-align: left;
    color: #fff;
    animation: slideInLeft 1s ease-in-out;
    
    h1 {
      font-size: 2.5rem;
      span {
        color: #1A4C39;
        font-weight: bold;
      }
    }
    
    p {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

/* Why Choose Us section */
.choosebox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;

  .chooseus {
    width: 45%;
    margin-bottom: 20px;
    box-shadow: 0px 2px 10px 4px #9d9999;
    padding: 20px;
    animation: fadeIn 0.5s ease-in-out;
    
    &:hover {
      transform: scale(1.05);
      transition: transform 0.3s ease-in-out;
    }
  }
}

/* Number Served section */
.number-section {
  padding: 50px 0;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #ed9121;
}

.noofchefs, .restrauntsserved, .customers {
  text-align: center;
  h1 {
    color: #1A4C39;
    font-size: 3rem;
  }

  h5 {
    color: #666;
  }
}

/* How It Works section */
.howitworks-section {
  padding-bottom: 50px;
  
  .howitworks {
    display: flex;
    justify-content: space-between;

    .register, .selectchefs, .requirements, .getachef {
      padding: 20px;
    }
  }
}

/* Testimonial Section */
.testimonial-section {
  padding: 50px 0;

  .slick-slider {
    .slick-arrow {
      background: #1A4C39;
      color: #fff;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      z-index: 9999;
    }

    .slick-list {
      .slick-slide {
        opacity: 0.7;
        background-color: #1A4C39;
        margin: 0 10px;
        
        .slick-text {
          .MuiCard-root {
            background-color: #1A4C39;
            color: #fff;
          }
        }

        &.slick-active {
          opacity: 1;
        }
      }
    }
  }
}
/* mobile css */

/* @media screen and (max-width: 480px) {
  .banner-section {
    .overtext {
      h1 {
        font-size: 1.5rem;
      }
    }
  }

  .chooseus {
    padding: 15px;
  }

  .testimonial-section {
    .slick-slider {
      .slick-arrow {
        height: 30px;
        width: 30px;
      }
    }
  }
} */


@media screen and (max-width:940px){
  .container {
    padding: 50px 25px;
  }
  .section.banner-section {
    height: 40vh;
    margin-top: 100px;
    
    .overtext {
      top: 10%;
      left: 5%;
      h1 {
        font-size: 2rem;
      }
    }
  }

  .body-container {
    padding: 25px;
  }
  
  /* .navbar img */
  img {
    /* display: flex; */
    height: 60px;
    padding: 8px 0px;
    margin: 0px;
    width: 72%;
  }

  .navbar .demo_btn{
    display: none;
  }

  .navbar {
    justify-content: space-between;
      a {
        flex-basis: 35%;
        img {
          width: 100%;
        }
      }
    }

  .navdiv {
    flex-basis: 45%;
  }

  .navdiv ul {
    display: none;
  }

  .navdiv .menu-icon {
    color: #fff;
    display: block;
    float: right;
    padding: 0px;
    margin: 0px;
  }

  .profile-img {
    display: none;
  }

  .sx-profile .profile-img {
    display: block;
    margin-right: 8px;
    width: 35px;
    height: 50px;
  }
  
  .drawer-list li a{
    color: grey;
  }

  .drawer-list li .active{
    color: rgb(117 219 80)
  }

  .drawer-list li a:hover{
    color: rgb(117 219 80)
  }

  .login {
    background: grey;
    flex-direction: column;
  }

  .otp_login, .sso_login{
    display: block;
  }

  .otp_login {
    width: 100%;
  }

  .sso_login {
    background-color: transparent;
  }
  
  .email_phone_box{
    box-shadow: none;
    width: 100%;
  }

  .wallpaper {
    height: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-size: auto;
  }

  .wallpaper button {
    display: inline-flex;
  }

  .wall-txt {
    font-size: 50px;
    padding:0px
  }

  .driving-sec img {
    width: 100%;
    height: 220px;
    display: inline-flex;
    padding: 0px;
    margin-left: 0px;
  }

  .globe-wall {
    background-size: contain;
    background-repeat: no-repeat;
  }

  .globe-wall p {
    font-size: 20px;
  }

  .divBtn-grps {
    justify-content: space-between;
  }

  .demo_btn.ex-demo-btn {
    font-size: 13px;
  }

  .ex-demo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 300px;
  }

  .car-image {
    flex-direction: column;
    align-items: center;
  }

  .car-image img {
    width: 100%;
  }

  .contact-container {
    flex-direction: column;
  }

  .address-container {
    flex-direction: column;
  }

  .pricing-img img {
    width: 100%;
    padding: 0;
    height: 250px;
  }
  
  .payment-form {
    width: 100%;
  }

  .payment-row {
    margin:0px;
    flex-direction: column;
  }

  .payment-row .col-input {
    align-items: baseline;
    margin-bottom: 20px;
    flex-flow: column;
  }

  .payment-row .input-label {
    padding: 0px;
    padding-right: 10px;
    width: auto;
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .col-span .amount-type {
    top: 60%;
  }

  .footer .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0px;
  }

  .footer-container .footer_privacy {
    display: flex;
    flex-direction: column;
  }

  /* mobile new css */
  .btn-group {
    display: none;
  }

  .drawer-list .xs-list-item {
    display: flex;
  }
}